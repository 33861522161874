// Import libs
import React from "react";

// Import shared components
import {
  Reference,
  ReferenceGroup
} from "@windish/shared";

// Component
function Impact() {

  // Write the final component code to DOM
  return (
    <ReferenceGroup>
      <Reference
        organisation="Trifs"
        description="Contributed to 7-figure revenue at the Slovene cloud innovator, by implementing prestudies. Established and scaled design operations to meet 200% company growth over a 4 year period."
      />
      <Reference
        organisation="Kolektor"
        description="Helped the international manufacturer deploy collaborative robots to factories in under a year. Improved human-robot interaction and accelerated deployments by co-creating a design system."
      />
      <Reference
        organisation="Ericsson"
        description="Supported a global telecom OEM for 4 years. A series of prestudies helped shape their IoT services portfolio, while better design integration in production helped reduce cost &amp; time to market."
      />
      <Reference
        organisation="Dnevnik"
        description="Led the graphics desk at Slovenian newspaper to international recognition in 3 years. Co-producted the regular section Objektivno, that pioneered graphic &amp; data journalism in the region."
      />
    </ReferenceGroup>

  );

}

// Export the component for use
export default Impact;
