// Import libs
import React from "react";
import { graphql } from "gatsby";

// Import shared components
import {
	Button,
	Cover,
	Container,
	Paragraph,
	PostContainer,
	PostCard,
	PostCardGroup
} from "@windish/shared";

// Import local components
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import Interests from "../../components/Interests";
import Impact from "../../components/Impact";
import Contact from "../../components/Contact";

// Import page-specific CSS
import "./index.css"

// Page constants
const pageTitle = "Works"
const pageDescription = "Pushing the limits of human-data interaction"

// Page
export default function Works({ data: { allMdx } }) {

	const postsData = allMdx.edges;

	// Render page
	return (
		<Layout pageTitle={pageTitle}>
			<Cover
				heading={pageDescription}
			/>
			<Container>
				<PostCardGroup>
					{postsData.map(edge => (
						<PostCard
							key={edge.node.id}
							coverImage={edge.node.frontmatter.cover_image.childImageSharp.gatsbyImageData}
							coverImageAlt={edge.node.frontmatter.cover_image_alt}
							client={edge.node.frontmatter.client}
							heading={edge.node.frontmatter.heading}
							slug={edge.node.slug}
							draft={edge.node.frontmatter.draft}
							locked={edge.node.frontmatter.locked}
						/>
					))}
				</PostCardGroup>
			</Container>
			<PostContainer>
				<Paragraph>I have been fortunate to work with leading companies in the space of data products and data infrastructure. Latest collaborations include IoT solutions being deployed today by tier 1 companies from Scandinavia.</Paragraph>
			</PostContainer>
			<Container>
				<Impact />
				<Button label="See my full impact" icon="arrow_forward" animate="right" link={true} linkHref="/impact" />
			</Container>
			<PostContainer>
				<Paragraph>We have to understand the business objectives and user tasks that accessible data can help unlock, for the marvelous technologies we use to have any chance at demonstrating their real-life potential.</Paragraph>
			</PostContainer>
			<Container>
				<Interests />
			</Container>
			<Contact />
		</Layout>
	);

}

// Page metadata
export const Head = () => (
	<Seo
		title={pageTitle}
		description={pageDescription}
	>
		<script type="application/ld+json">
			{`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "${pageTitle}",
          "description": "${pageDescription}",
          "url": "https://vindis.io/works",
          "copyrightHolder": "Aljaž Vindiš",
          "copyrightYear": 2022,
					"author": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					},
					"publisher": {
						"@type": "Person",
						"name": "Aljaž Vindiš",
						"url": "https://vindis.io/"
					}
        }
      `}
		</script>
	</Seo>
)

// Page data
export const pageQuery = graphql`
	query {
    allMdx(
			filter: {
				published: { eq: true },
			},
      sort: {
				order: DESC,
				fields: [frontmatter___year]
			}
    ){
      edges {
        node {
          id
          slug
          frontmatter {
            cover_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            cover_image_alt
            heading
            client
            year
						draft
						locked
          }
        }
      }
    }
  }
`
