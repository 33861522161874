// Import libs
import React from "react";

// Import shared components
import {
  Feature,
  FeatureGroup
} from "@windish/shared";

// Component
function Interests() {

  // Write the final component code to DOM
  return (
    <FeatureGroup>
      <Feature
        heading="Universal control"
        description="Transcending limits of physical and digital user interfaces with IoT. Providing best one for each use-case, and having them all work in sync."
      />
      <Feature
        heading="Remote collaboration"
        description="Using digital twins and live data feeds to enable assistance from anywhere, anytime. Making decisions with best people, not the nearest ones."
      />
      <Feature
        heading="Higher transparency"
        description="Using visualization, sonification, physicalization to unveil states and events in a complex system, make them understandable to any user."
      />
      <Feature
        heading="Deeper insights"
        description="Democratizing analytics with dashboards and research portals. Supercharging advanced users with API access and in-depth resources."
      />
      <Feature
        heading="Smarter automation"
        description="Providing meaningful and trustworthy automation based on a deep understanding of workflows, people's habits, and legal boundaries."
      />
      <Feature
        heading="Fair sharing"
        description="Spreading the value of data beyond silos. Creating data brokerages that operate with security, integrity, and GDPR in mind."
      />
    </FeatureGroup>
  );

}

// Export the component for use
export default Interests;